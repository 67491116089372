/* eslint-disable @next/next/no-img-element */
import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { get, isEmpty } from 'lodash';
import Link from 'next/link';
import { FC, useMemo } from 'react';
import Container from '~/components/Container';
import Icon from '~/components/Icon';
import SectionTitle from '~/components/SectionTitle';
import Text from '~/components/Text';
import Colors from '~/constants/colors';
import { Identifiers, ProductListKey } from '~/constants/enum';
import { QUERY_GET_CMS_BLOCKS, QUERY_OPTIMISTIC_TRENDING } from '~/data/home';
import { Product } from '~/services/product';
import { transformProductItems } from '~/utils/transform';
import ProductsSlider from '../../components/ProductsSlider';
import styles from './Trending.module.scss';

import 'swiper/css/grid';
import { Grid } from 'swiper/modules';

interface TrendingProps {
    title?: string;
    image?: string;
    directLabel?: string;
    directUrl?: string;
    numberOfItems?: number;
    skip?: boolean;
    data?: Product[];
}

const Trending: FC<TrendingProps> = ({
    title = 'TRENDING',
    image,
    directLabel,
    directUrl,
    // directLabel = "See All",
    // directUrl = "#",
    data,
    numberOfItems = 2.5,
    skip
}) => {
    const fetchBanner = useQuery(QUERY_GET_CMS_BLOCKS, {
        variables: {
            identifiers: [Identifiers.TrendingBanner]
        },
        skip,
        context: { fetchOptions: { method: 'GET' } }
    });

    const {
        data: dataProducts,
        loading,
        called,
        fetchMore
    } = useQuery(QUERY_OPTIMISTIC_TRENDING, {
        variables: {
            pageSize: 16
        },
        context: { fetchOptions: { method: 'GET' } }
    });

    const _image: string = useMemo(() => {
        if (!!skip) {
            return image;
        }
        if (isEmpty(fetchBanner?.data?.cmsBlocks?.items?.[0])) return {};

        const cmsBanner = get(fetchBanner?.data?.cmsBlocks?.items?.[0], 'content', {});
        const img = cmsBanner?.match(/src="(.*?)"/)?.[1] || '';
        return img;
    }, [fetchBanner?.data?.cmsBlocks, image, skip]);

    const _data: Partial<Product>[] = useMemo(() => {
        if (!!skip) {
            return data || [];
        }

        return transformProductItems(dataProducts?.trendingProducts?.items) || [];
    }, [skip, data, dataProducts?.trendingProducts]);

    const _pageInfo = useMemo(() => {
        if (!!skip) {
            return {};
        } else {
            return dataProducts?.trendingProducts?.page_info || {};
        }
    }, [dataProducts?.trendingProducts, skip]);

    if (!!called && isEmpty(_data)) return null;
    const handleFecthMore = (current: number) => {
        if (current <= _pageInfo?.total_pages) {
            fetchMore({
                variables: {
                    currentPage: current
                },
                updateQuery(previousQueryResult, options) {
                    const { fetchMoreResult } = options;
                    if (!fetchMoreResult) return previousQueryResult;
                    return Object.assign({}, previousQueryResult, {
                        trendingProducts: {
                            ...fetchMoreResult.trendingProducts,
                            items: [
                                ...previousQueryResult.trendingProducts.items,
                                ...fetchMoreResult.trendingProducts.items
                            ]
                        }
                    });
                }
            });
        }
    };

    const _numberOfItems = !!isEmpty(_image) ? 5.5 : numberOfItems;

    return (
        <Container rootClassName={styles.trending_wrapper} className={styles.trending}>
            <SectionTitle
                text={title}
                textProps={{ type: 'headline-3', color: Colors.GREY_900, weight: 800 }}
                action={
                    directUrl ? (
                        <Link href={directUrl} className="flex items-center gap-1 text-primary">
                            <Text type="title-1">{directLabel}</Text>
                            <Icon name="arrow-up-right" />
                        </Link>
                    ) : undefined
                }
            />
            <div className="flex flex-col gap-8 md:flex-row lg:gap-10">
                {!isEmpty(_image) && (
                    <div className={classNames(styles.banner, 'hidden lg:block')}>
                        <img className="object-cover" src={_image} alt={title || 'Image'} height="100%" width="100%" />
                    </div>
                )}
                <div className="flex-1 overflow-hidden">
                    <ProductsSlider
                        listkey={ProductListKey.Trending}
                        products={_data}
                        slidesPerView={_numberOfItems}
                        grid={{
                            rows: 1
                        }}
                        modules={[Grid]}
                        loading={loading}
                        onLoadingMore={() => handleFecthMore(_pageInfo?.current_page + 1)}
                        hasNext={false}
                        breakpoints={{
                            1024: {
                                slidesPerView: _numberOfItems,
                                grid: {
                                    rows: 2,
                                    fill: 'row'
                                }
                            },
                            768: {
                                slidesPerView: _numberOfItems - 2
                            }
                        }}
                    />
                </div>
            </div>
        </Container>
    );
};

export default Trending;
